/* api/Queries.js */
// https://backbencher.dev/articles/react-query-share-query-across-components-using-hooks

import { useQuery } from '@tanstack/react-query'
import { backendApi } from './Backend'

/**CheckSession asks the backend if this user is logged in */
export const useCheckSessionQuery = (param = {}, onSuccess = () => {}) => 
    (useQuery({
        queryKey: ['CheckSession'],
        queryFn: () => backendApi('checkSession', param),
        onSuccess,
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000/* 1 minute */,
        refetchIntervalInBackground: false,
        retry: 1,
        retryDelay: 3000/* 3 seconds */,
        enabled: navigator.onLine 
    }))


/**Retrieve data from backend */
export const useDataQuery = (type = '', onSuccess = () => {}, returnRefetch = false) => {
    const query = useQuery({
        queryKey: ['QueryData', type],
        queryFn: () => backendApi(`data-${type}`),
        onSuccess,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: 300000/* 5 minutes */,
        retry: 1,
        retryDelay: 10000/* 10 seconds */,
        enabled: navigator.onLine
    })
    return returnRefetch? query.refetch : query
}


/**Send a packet/action of data to the server */
// export const useAction = (type = '', param = {}) =>
//     (useMutation(type))
