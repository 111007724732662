import React from 'react'
import css from './HRPage.module.css'
import { Link } from 'react-router-dom'
import site from '../Site.module.css'

const HRPage = () => {
    return <main>
        <div className={site.pageHeaderBreadcrumbs}>
            <Link to="/">Home</Link> &#10147; <span>HR&nbsp;Information</span>
        </div>
        <br/>
        <h1>HR Information</h1>
        <div className='standardFlex' style={{gap: 'var(--space)'}}>
            {false && <ul>
                <li>Newest Updates
                    <ol>
                        <li>Musters</li>
                        <li>Staff Holidays</li>
                        <li>All hands</li>
                        <li>Birthdays this month</li>
                    </ol>
                </li>
                <li>Essential Documents
                    <ol>
                        <li>Benefits docs</li>
                        <li>Link to login <a href="https://www.uhcprovider.com/portal" target="_blank">UnitedHealthcare portal</a></li>
                        <li>Summary Guide PDF</li>
                        <li>Employee Handbook</li>
                    </ol>
                </li>
                <li>Staff Listing
                    <ol>
                        <li>name</li>
                        <li>image</li>
                        <li>description</li>
                        <li>title</li>
                        <li>short-bio</li>
                    </ol>
                </li>
                <li><a href="https://portal.adp.com/public/index.htm" target="_blank">ADP Portal Login</a></li>
                <li>etc.</li>
            </ul>}
            <div className={css.links}>
                <h2>Links & Downloads</h2>
                <ul>
                    <li><a href="https://portal.adp.com/public/index.htm" target="_blank">ADP Login</a></li>
                    {/* <li><a href="https://portal.echelonfront.com/downloads/2022 Benefits Guide.pdf">Benefits Guide 2022.pdf</a></li>
                    <li><a href="https://www.uhcprovider.com/portal" target="_blank">UnitedHealthcare Login</a></li>
                    <li><a href="https://portal.echelonfront.com/downloads/EF Birthdays.xlsx">Employee Birthdays.xls</a></li> */}
                    {/* <li>Employee Handbook</li> */}
                </ul>
            </div>
            <div className={css.holidays}>
                <h2>EF Observed Holidays</h2>
                <ul>
                    <li>New Year’s Day</li>
                    <li>Martin Luther King Jr. Day</li>
                    <li>President’s Day</li>
                    <li>Memorial Day</li>
                    <li>Independence Day</li>
                    <li>Labor Day</li>
                    <li>Veteran’s Day</li>
                    <li>Thanksgiving Day</li>
                    <li>Day After Thanksgiving</li>
                    <li>Christmas Day</li>
                </ul>
            </div>
        </div>
    </main>
}

export default HRPage