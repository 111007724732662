import React from 'react'
import {EventScheduleHomepage} from './EventSchedule'
import site from '../Site.module.css'

const Home = () => <div className="page">
    <div className={site.pageHeaderBreadcrumbs}>
        <span>Home</span>
    </div>
    <br/>
    <EventScheduleHomepage/>
</div>

export default Home