/* components/EditEventSchedule.js */
import React, {useState, useCallback, useEffect} from 'react'
import {Link} from 'react-router-dom'
import eventStyle from './EventSchedule.module.css'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingAnimation from './LoadingAnimation'
import { backendApi } from '../api/Backend'
import { displayDate } from '../helpers/helpers'
import {UserContext} from '../App'

const eventScheduleQueryOptions = {
    refetchInterval: 300*1000,
    refetchOnReconnect: false,
    refetchOnRemount: false, 
    refetchOnWindowFocus: false
}
//
const useEventSchedule = (options = {}) => useQuery({
    queryKey: ['eventSchedule'],
    queryFn: () => backendApi('eventScheduleAll'),
    ...eventScheduleQueryOptions,
    ...options
})

const EventScheduleHomepage = () => {
    const {user} = React.useContext(UserContext)
    const events = useEventSchedule({refetchInterval: 30000})

    return <div>
        <div className='standardFlex'>
            <h1>The Schedule</h1>
            {user.admin && <Link to="/editEventSchedule" className='fontSize-small'>Edit Events</Link>}
        </div>
        <br/>
        {events.isLoading && <LoadingAnimation/>}
        {events.data && <EventsList events={events.data.data}/>}
    </div>
}

const eventScheduleTypes = [
    {
        abbr: 'BF',
        name: 'Battlefield Review'
    },
    {
        abbr: 'MUSTER',
        name: 'Muster'
    },
    {
        abbr: 'FTX',
        name: 'FTX Field Training Exercise'
    },
    {
        abbr: 'COUNCIL',
        name: 'Council'
    },
    {
        abbr: 'RC',
        name: 'Roll Call'
    },
    {
        abbr: 'WAC',
        name: 'Women\’s Assembly Conference'
    },
    {
        abbr: 'JLE',
        name: 'Jocko Live Events'
    }
]
//
const EventsList = ({events = []}) => {
    const {filter, resetFilter, fieldset, displayEvents, displayEventsByType} = useEventScheduleFilter(events)

    return <div className={eventStyle.EventsListWrapper}>
        {fieldset}
        <table className={eventStyle.EventSchedule}>{
            Object.keys(displayEvents).length?
                eventScheduleTypes.map(type => <tbody key={`eventScheduleTypes:${type.abbr}`} className={!Object.keys(displayEventsByType).includes(type.abbr)? filter.type.length? eventStyle.hide : eventStyle.disabled : ''}>
                    <tr>
                        <th colSpan={5} className='fontSize-prompt'>{type.name.toUpperCase()}</th>
                    </tr>
                    {displayEvents.filter(event => event.type.toLowerCase() === type.abbr.toLowerCase()).map(event => <EventRow {...{event}}/>)}
                </tbody>)
                : <tr>
                    <td colSpan={5}>
                        <button onClick={resetFilter}>
                        No results.&nbsp; Reset filter?
                        </button>
                    </td>
                </tr>
        }</table>
    </div>
}

const filterEventsDefault = {
    type: '',
    showPast: false
}
//
const useEventScheduleFilter = (events = [], options = {showDrafts: false}) => {
    const [filter, setFilter] = useState(filterEventsDefault)
    const resetFilter = useCallback(() => setFilter(filterEventsDefault))
    
    let displayEvents = events
    /**Filter? */
    if (filter.type.length) displayEvents = displayEvents.filter(event => event.type.toLowerCase() === filter.type.toLowerCase())
    if (!filter.showPast) displayEvents = displayEvents.filter(event => !event.dateRangeBegin.length || new Date(event.dateRangeEnd) >= new Date())

    let displayEventsByType = displayEvents.reduce((types, event) => {
        types[event.type] = [...types[event.type] || [], event]
        return types
    }, [])
    
    /**Filter out drafts */
    if (!options.showDrafts) displayEvents = displayEvents.filter(event => !event.draft)
    
    let fieldset = <fieldset key={`useEventScheduleFilter:fieldset`} className={`${eventStyle.filter} no-select fontSize-small`}>
        <legend onClick={resetFilter}>Filter</legend>
        <label htmlFor={`EventsList:${filter.type}`}>
            By Type&nbsp;
            <select id={`EventsList:${filter.type}`}
                value={filter.type}
                onChange={e => setFilter({
                    ...filter,
                    type: e.target.value
                })}>
                <option value="">--</option>
                {eventScheduleTypes.map(type => 
                    <option key={`EventsList:${filter.type}:${type.name}`} 
                        value={type.abbr.length? type.abbr : type.name}
                        >{type.name.toUpperCase()}</option>)}
            </select>
        </label>
        <label htmlFor={`EventsList:${filter.showPast}`}>
            Show History&nbsp;
            <input id={`EventsList:${filter.showPast}`} 
                type='checkbox' 
                checked={filter.showPast} 
                onChange={e => setFilter({
                    ...filter,
                    showPast: !filter.showPast
                })}/>
        </label>
    </fieldset>
    
    return {
        filter,
        resetFilter,
        fieldset,
        displayEvents,
        displayEventsByType
    }
}

const isExpired = ({dateRangeEnd}) => new Date(dateRangeEnd) < new Date()

const EventRow = ({event}) => 
    <tr key={`EventsList:EventRow:${event.id}`} className={`${eventStyle.EventRow} ${isExpired(event)? eventStyle.isExpired : ''}`}>
        <td>{event.type}{event.givenId > 0 && `:${`00${event.givenId}`.slice(-3)}`}</td>
        <td>
            <time>{displayDate(event.dateRangeBegin)}</time>
            {event.dateRangeEnd !== event.dateRangeBegin && <>&nbsp;&ndash;&nbsp;<time>{displayDate(event.dateRangeEnd)}</time></>}
        </td>
        <td>{event.location.length > 0? event.location : <i>Remote</i>}</td>
        <td>{event.link && <>&nbsp;<a href={event.link}>Event Link</a></>}</td>
    </tr>

// const EventModule = ({event}) => {
//     return <Link key={`EventsList:${event.id}`} to={`/event/${event.type}-${`00${event.givenId}`.slice(-3)}`} style={{display: 'contents'}}>
//         <div className={eventStyle.Event}>
//             {event.type}:{event.givenId}
//             &nbsp;
//             {event.location.length > 0 && `(${event.location})`}
//             &nbsp;
//             {event.dateRangeBegin}
//             &ndash;
//             {event.dateRangeEnd}
//             {/* {event.link.length > 0 && <>&nbsp;<a href={event.link}>Event Link</a></>} */}
//             {/* {JSON.stringify(event)} */}
//         </div>
//     </Link>
// }





const EditEventSchedule = () => {
    const events = useEventSchedule({refetchInterval: 10000})

    //
    const eventMutation = useMutateEvent()
    const [addEvent, setAddEvent] = useState(defaultAddEvent)
    const [addEventRow, setAddEventRow] = useState(false)
    let addEventValid = (addEvent.type && addEvent.dateRangeBegin && addEvent.dateRangeEnd)
    let addEventReady = addEventValid && !eventMutation.isLoading
    /**Add the event if valid */
    useEffect(() => addEventValid && addEventReady && eventMutation.mutate(addEvent, {
        onSuccess: data => data.success && (setAddEvent(defaultAddEvent) || setAddEventRow(false) || events.refetch())
    }), [addEvent])
    
    //
    const {fieldset, displayEvents} = useEventScheduleFilter(events.data? events.data.data : [], {sort: 'recentlyCreated', showDrafts: true})

    return <div className={eventStyle.EditEventScheduleWrapper}>
        <h1 style={{margin: 0}}>Edit Schedule</h1>
        {fieldset}
        {events.isLoading && <LoadingAnimation/>}
        {events.data && <table className={eventStyle.EditEventSchedule}>
            <thead>
                <tr>
                    <th style={{minWidth: '5ch'}}>{!mutateEvent.isLoading &&
                        <button onClick={() => setAddEventRow(!addEventRow)} 
                        className='fontSize-normal'
                        style={{
                            padding: 'var(--smidge) var(--padding)',
                            aspectRatio: '1/1'
                        }}
                        >
                            {!addEventRow? <b>&#43;</b> : <>&#10005;</>}
                        </button>
                    }</th>
                    <th>Type{addEventRow && reqFieldMarker}</th>
                    <th>ID</th>
                    <th>Begin{addEventRow && reqFieldMarker}</th>
                    <th>End{addEventRow && reqFieldMarker}</th>
                    <th className='fontSize-small'>Location</th>
                    <th className='fontSize-small'>Link</th>
                    {/* <th className='fontSize-small' title="Feature above other future events">Feature</th> */}
                    <th className='fontSize-small'>Draft</th>
                    <th className='fontSize-small'>Notes</th>
                </tr>
            </thead>
            <tbody>
                {addEventRow && <EditEventScheduleRow key={`edit-event:add`} event={addEvent} refetchEvents={events.refetch} {...{setAddEvent, addEvent, setAddEventRow}}/>}
                {displayEvents.map(event => <EditEventScheduleRow key={`edit-event:${event.id}`} {...{event}} refetchEvents={events.refetch}/>)}
            </tbody>
        </table>}
    </div>
}

const reqFieldMarker = <span className='no-select' style={{color: 'var(--ef-red)'}}>&#42;</span>

const mutateEvent = param => backendApi('EventScheduleUpdate', param)
//
const useMutateEvent = (useOptions = {}) => useMutation({
    mutationKey: ['MutateEvent'],
    mutationFn: mutateEvent,
    ...useOptions
})
//
const eventDefault = {
    id: null,
    type: '',
    givenId: '',
    dateRangeBegin: '',
    dateRangeEnd: '',
    location: '',
    highlight: 0,
    draft: 1,
    notes: null,
    link: null
}
const defaultAddEvent = {...eventDefault, action: 'add'}
//
const EditEventScheduleRow = ({event = {id: null}, refetchEvents, addEvent, setAddEvent = () => {}, setAddEventRow = () => {}}) => {
    const [updateEvent, setUpdateEvent] = useState(!event.id? addEvent : event)
    
    const onSuccess = ({success}) => success && refetchEvents()
    const eventMutation = useMutateEvent()
    
    /**Check every value & send values to server */
    const checkAndSave = () => 
        (event.id && (updateEvent.type !== event.type
        || updateEvent.givenId !== event.givenId
        || updateEvent.dateRangeBegin !== event.dateRangeBegin
        || updateEvent.dateRangeEnd !== event.dateRangeEnd
        || updateEvent.location !== event.location
        || updateEvent.link !== event.link
        || updateEvent.highlight !== event.highlight
        || updateEvent.draft !== event.draft
        || updateEvent.notes !== event.notes
        )) && eventMutation.mutate({
            ...updateEvent,
            action: 'update'
        }, {onSuccess})
    useEffect(checkAndSave, [updateEvent.type, updateEvent.draft])

    const handleDeleteEvent = !event.id? null : () => eventMutation.mutate({
        ...updateEvent,
        action: 'delete'
    }, {onSuccess})

    let display = !event.id? addEvent : updateEvent
    let setChange = data => !event.id? setAddEvent(data) : setUpdateEvent(data)

    return <tr className={!event.id? eventStyle.addRow : ''}>
        <td className='no-select no-wrap'>
            {eventMutation.isLoading? <LoadingAnimation/> 
                : !display.id? <span style={{fontWeight: 700, color: 'var(--ef-red-dark)'}}>New&nbsp;&#10132;</span> 
                    : <button title="Delete this event" onClick={handleDeleteEvent} className='mini'>&#215;</button>}
        </td>
        <td>
            <select value={display.type} 
            disabled={eventMutation.isLoading}
            onBlur={checkAndSave}
            onChange={e => setChange({
                ...display,
                type: e.target.value
            })}>
                <option value={''} disabled={true}>--</option>
                {eventScheduleTypes.map(type => <option key={`EditEventScheduleRow:${event.id}:eventScheduleTypes:${type.abbr}`} value={type.abbr}>{type.abbr}</option>)}
            </select>
        </td>
        <td>
            <input type='text' 
                value={`000${display.givenId}`.slice(-3)} 
                style={{width: 'var(--space)'}}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    givenId: e.target.value
                })}/>
        </td>
        <td>
            <input type="date"
                value={display.dateRangeBegin}
                disabled={eventMutation.isLoading}
                // onBlur={checkAndSave}
                onChange={e => {
                    setChange({
                        ...display,
                        dateRangeBegin: e.target.value
                    })
                    checkAndSave()
                }}/>
        </td>
        <td>
            <input type="date" 
                value={display.dateRangeEnd}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    dateRangeEnd: e.target.value
                })}/>
            {/* <input type="checkbox"
                checked={display.dateRangeBegin === display.dateRangeEnd}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    dateRangeEnd: display.dateRangeBegin.length? display.dateRangeBegin : '0000-00-00'
                })}/> */}
        </td>
        <td>
            <input type="text" 
                value={display.location}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    location: e.target.value
                })}/>
        </td>
        <td>
            <input value={display.link}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    link: e.target.value
                })}/>
        </td>
        {/* <td style={{textAlign: 'center'}}>
            <input type="checkbox"
                checked={display.highlight}
                disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    highlight: e.target.value === 'on'
                })}/>
        </td> */}
        <td style={{textAlign: 'center'}}>
            <input type="checkbox"
                checked={display.draft}
                disabled={!event.id || eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => {console.info(e);setChange({
                    ...display,
                    draft: e.target.checked? 1 : 0
                })}}/>
        </td>
        <td>
            <textarea disabled={eventMutation.isLoading}
                onBlur={checkAndSave}
                onChange={e => setChange({
                    ...display,
                    notes: e.target.value
                })}
                value={display.notes || ''}
                style={{minWidth: '14ch'}}/>
        </td>
    </tr>
}



export {
    EventScheduleHomepage,
    EditEventSchedule
}