
import { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { EnvContext, UserContext } from '../App'

import { useMutation } from '@tanstack/react-query'
import { useCheckSessionQuery } from '../api/Queries'

import { range } from 'lodash'
import { noWidows, validateEmailAddress } from '../helpers/helpers'
import style from './Login.module.css'

import { backendApi } from '../api/Backend'
import LoadingAnimation from './LoadingAnimation'


const Login = () => {
    // console.count('Login')
    
    const {env} = useContext(EnvContext)
    const {user} = useContext(UserContext)

    /**loginIdentity */
    const refInputLoginIdentity = useRef(null)
    const [loginIdentity, setLoginIdentity] = useState('')
    const handleLoginIdentityChange = useCallback(e => setLoginIdentity(e.target.value), [])
    const handleLoginIdentityKeydown = useCallback(e => {
        switch (e.code) {
            case 'Enter':
                if (!loginIdentityReady) {
                    /**Focus on password input? */
                    // if (validateEmailAddress(user.loginIdentity)) refInputLoginPassword.current.focus()
                } else loginAction()
            break;

            case 'Tab':
                /**Focus on password input? */
                if (!loginIdentityReady) {}//refInputLoginPassword.current.focus()
                else loginAction()
            break;

            case 'Escape':
                resetLoginForm()
            break;
            
            default:
            break;
        }
    }, [])

    /**Handle the error message to the user */
    const [errorMessages, setErrorMessages] = useState([])

    /**States for input focus */
    const [identityInputFocus, setIdentityInputFocus] = useState(false)

    /**We disable the Send-Login button until the loginIdentity is inputted & valid */
    const [loginIdentityReady, setLoginIdentityReady] = useState(null)
    const [loginIdentityAccepted, setLoginIdentityAccepted] = useState(null)

    const sendLoginIdentity = useMutation({
        mutationKey: ['SendLoginIdentity'],
        mutationFn: data => backendApi('loginIdentity', data),
        onError: () => setErrorMessages(['Cannot send email at this time. ']),
        onSuccess: response => {
            if (!response.success) {
                if (response.userMessages.length) {
                    setErrorMessages(response.userMessages)
                    window.navigator.vibrate(200)
                }
            } else {
                setErrorMessages([])
                setLoginIdentityAccepted(true)
            }
        }
    })

    /**/
    /**Ask backend if user has session */
    const checkSession = useCheckSessionQuery({enabled: false})

    /**Check the user's input for loginIdentity validity 
     * and determine if a login attempt is ready */
    const validateLoginIdentityReady = () => {
        let valid = validateEmailAddress(loginIdentity)
        /**/
        if (!loginIdentity.length) setLoginIdentityReady(false)
        else if (!user.id && !sendLoginIdentity.isLoading) setLoginIdentityReady(valid)
        else setLoginIdentityReady(false)
    }
    useEffect(validateLoginIdentityReady, [loginIdentity])

    
    /**Resetting the login form */
    const resetLoginForm = useCallback(() => {
        setLoginIdentity('')
        setErrorMessages([])
        setLoginIdentityAccepted(false)
        setTimeout(() => refInputLoginIdentity.current.focus(), 1500)
    }, [])

    const loginAction = () => {
        if (loginIdentityReady && !sendLoginIdentity.isLoading) {
            /**Set state for loading the identity search */
            setErrorMessages([])
            sendLoginIdentity.mutate({loginIdentity})
        } else refInputLoginIdentity.current.focus()
    }

    /**Autofocus */
    // useEffect(() => refInputLoginIdentity && refInputLoginIdentity.current.focus(), [refInputLoginIdentity])

    // console.info(checkSession)

    return <div className={`${style.wrapper} ${!user.id? style.loggedOut : style.loggedIn}`}>
        <div className={[
            style.container,
            !sendLoginIdentity.isLoading? '' : `wait`,
            !user.id? style.loggedOut : style.loggedIn
        ].join(' ')}>
            {false && checkSession.isLoading && !checkSession.isFetched? 
                <LoadingAnimation/>
                :
                <div className={`${style.loginForm} ${!user.id? style.show : style.hide}`}>
                    <p className={[
                        style.msg,
                        'fontSize-prompt',
                        !sendLoginIdentity.isLoading? style.show : style.hide
                    ].join(' ')}>Welcome to our portal.</p>
                    <div className={[
                        style.inputWrap,
                        loginIdentity.length? '' : style.empty,
                        sendLoginIdentity.isLoading? style.disabled : '',
                        identityInputFocus? style.focus : ''
                    ].join(' ')}>
                        <form style={{display: 'contents'}} onSubmit={e => {
                            e.preventDefault()
                            loginAction()
                        }}>
                            <input id="loginIdentity"
                                ref={refInputLoginIdentity}
                                name="loginIdentity"
                                type="email"
                                placeholder="Your Email Address"
                                className={`${style.input} fontSize-medium ${style.identity}`}
                                autoFocus={true}
                                tabIndex="1"
                                value={loginIdentity}
                                onKeyDownCapture={handleLoginIdentityKeydown}
                                onChange={handleLoginIdentityChange}
                                onFocus={() => setIdentityInputFocus(true)}
                                onBlur={() => setIdentityInputFocus(false)}
                                disabled={sendLoginIdentity.isLoading || env.offline || loginIdentityAccepted}
                                autoComplete="off"
                                data-lpignore="true"
                            />
                        </form>
                        <div className={[
                                'fontSize-header',
                                style.identityInputUnlock,
                                loginIdentity 
                                || sendLoginIdentity.isLoading? 
                                    `${style.show} ${identityInputFocus? style.focus : ''}`
                                    : style.hide,
                                'no-select'
                            ].join(' ')}
                            /**We cancel the identity search */
                            onClick={resetLoginForm}
                        >
                            <span>&#10539;</span>
                        </div>
                    </div>
                    <div className={[
                        'no-select',
                        style.button,
                        loginIdentityReady? style.ready : style.notReady,
                        !loginIdentityAccepted && !sendLoginIdentity.isLoading? style.show : style.hide
                    ].join(' ')} 
                    tabIndex="2"
                    onClick={loginAction}
                    onKeyPress={e => ['Enter', 'Space'].indexOf(e.code) && loginAction()}>
                        <div className={`${style.text} fontSize-medium`}>
                            Send Link
                        </div>
                    </div>
                    {loginIdentityAccepted && <VerifyCode time={!sendLoginIdentity.isSuccess? null : sendLoginIdentity.data.time}/>}
                </div>
            }
            <div className={[
                style.error,
                'yes-select', 
                'fontSize-small', 
                errorMessages.length && errorMessages.length? style.show : style.hide
            ].join(' ')}
            onClick={() => setErrorMessages([])}
            >
                <p>Errors: {errorMessages.map((err, i) => <span key={`loginError:${i}`}>{noWidows(err)}</span>)}</p>
            </div>
            {sendLoginIdentity.isLoading && <center><LoadingAnimation/></center>}
        </div>
    </div>
}

/**Setup the rules */
const amountOfDigits = 4
const invalidCharacters = [' ']
const defaultCode = range(0, amountOfDigits).map(() => (''))
//
const VerifyCode = ({time}) => {
    // console.count('VerifyCode')

    const digitRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ]

    /**Hold digit state */
    const [allDigits, setAllDigits] = useState(defaultCode)
    
    /**Handle keyboard actions */
    const handleKeyDown = digitIndex => (e => {
        switch (e.key) {
            case 'Backspace': 
                /**Remove digit */
                if (allDigits[digitIndex].length === 1) setAllDigits([
                    ...!digitIndex? [] : allDigits.slice(0, digitIndex),
                    '',
                    ...digitIndex+1 < amountOfDigits? allDigits.slice(digitIndex+1) : []
                ])
                /**Focus to the previous input */
                if (digitIndex) digitRefs[digitIndex-1].current.focus()
                break;
            default: break;
        }
    })

    /**Handle changes to inputs */
    const handleDigitChange = digitIndex => (event => {
        if (event.nativeEvent.data) {
            event.preventDefault()
            let digitVal = event.nativeEvent.data//event.target.value.trim()
            let newDigits = [...allDigits]
            newDigits[digitIndex] = digitVal
            setAllDigits(newDigits)
            if (digitVal.length === 1 && digitIndex < amountOfDigits-1) setTimeout(() => digitRefs[digitIndex+1].current.focus(), 25)
        }
    })
    //
    const handlePasteEvent = digitIndex => (event => {
        event.preventDefault()
        /**Get the text from the clipboard */
        let pasteTest = event.clipboardData.getData('Text')
            /**Remove any spaces at both ends */
            .trim()
            /**Make into array for filtering characters, such as any spaces */
            .split('')
            /**Filter out any invalid characters */
            .filter(char => !invalidCharacters.includes(char))
            /**Put it back together */
            .join('')
        //
        if (pasteTest.length > 0) {
            let beforeText = allDigits.slice(0, digitIndex)
            let afterText = allDigits.slice(beforeText.length+pasteTest.length)
            let newText = [...beforeText, ...pasteTest.split(''), ...afterText].slice(0, amountOfDigits)
            setAllDigits(newText)
        }
    })

    const checkSession = useCheckSessionQuery({enabled: false})
    
    
    /**Submit it to the server */
    const sendCodeMutation = useMutation({
        mutationKey: ['VerifyCodeSubmit'],
        mutationFn: data => backendApi('verifyCode', data),
        onSuccess: data => data.success && window.location.reload()//&& checkSession.refetch()
    })

    const clearVerifyCode = useCallback(() => 
        setAllDigits(defaultCode)
        && sendCodeMutation.reset()
    , [])
    
    const handleVerifySubmit = e => {
        e.preventDefault()
        sendCodeMutation.mutate({
            code: allDigits
        }, {
            onSuccess: data => data.success && clearVerifyCode()
        })
    }

    let formDisabled = checkSession.isLoading || checkSession.isFetching || sendCodeMutation.isLoading

    let firstDigitNotReady = allDigits.join('').trim().length < 1

    /**Focus on the first digit input when it becomes available */
    useEffect(() => firstDigitNotReady && digitRefs[0] && digitRefs[0].current.focus(), [digitRefs])
    
    return <div className={style.verifyWrapper}>
        <p>We emailed you a 4 digit code
            <br/>It's from <u>noreply@echelonfront.com</u>
            <br/>Check your inbox and junk folder
            {/* <br/>2 hours from: {time} */}
        </p>
        <form disabled={formDisabled} onSubmit={handleVerifySubmit} type="post">
            <fieldset disabled={formDisabled} className={style.verifyDigits}>
                {allDigits.map((digit, i) => 
                    <input className='fontSize-prompt'
                        key={`VerifyCodeDigit:${i}`} 
                        required={true}
                        ref={digitRefs[i]} 
                        tabIndex={`3${i}`}
                        value={digit} 
                        onPaste={handlePasteEvent(i)} 
                        onChange={handleDigitChange(i)} 
                        onKeyDown={handleKeyDown(i)}
                        onFocus={e => e.target.select()}
                        onClick={e => e.target.select()}
                        maxLength="1"
                        readOnly={sendCodeMutation.isLoading || (i && firstDigitNotReady)}
                        />
                )}
                <button className={style.verify}
                    type="submit" 
                    tabIndex={`3${allDigits.length}`} 
                    // disabled={!allDigitsReady()}
                    >Verify</button>
            </fieldset>
        </form>
        {sendCodeMutation.isSuccess 
        && !sendCodeMutation.data.success
        && <div onClick={clearVerifyCode}>
            {/* <strong>Error</strong> */}
            {sendCodeMutation.data.userMessages.length > 0
            && <div>{sendCodeMutation.data.userMessages.map(msg => <p>{msg}</p>)}</div>}
        </div>}
    </div>
}

export default Login