import { timeUnix } from "../helpers/helpers"

const initialLoadTime = timeUnix()

const envInitial = {
    offline: navigator.onLine,
    time: initialLoadTime,
    darkMode: /**According to system settings */
        (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),
    userSessionCookieName: 'efportal_cookie',
    mobileSize: null,
    dataLoadedTime: null
}

const envReducer = (state, action) => {
    switch (action.type) {
        case 'offline': {
            return {
                ...state,
                offline: action.payload
            }
        }
        case 'darkMode': {
            return {
                ...state,
                darkMode: action.payload
            }
        }
        case 'toggleDarkMode': {
            return {
                ...state,
                darkMode: !state.darkMode
            }
        }
        case 'dataLoadedTime': {
            return {
                ...state,
                dataLoadedTime: timeUnix()
            }
        }
        case 'reset' : return envInitial;
        default: return state;
    }
}

export {
    envInitial,
    envReducer
};