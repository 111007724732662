import React, { useEffect } from 'react'
import style from './Footer.module.css'

import {EnvContext, UserContext} from '../App'

const Footer = () => {
    const {env, envDispatch} = React.useContext(EnvContext)
    const {user} = React.useContext(UserContext)

    /**Auto-toggle darkMode
     * According to system settings
     * Set event listener for darkMode */
    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            let darkModeChangeTo = null
            if (e.matches && !env.darkMode) 
                darkModeChangeTo = true
            else if (env.darkMode) 
                darkModeChangeTo = false
            if (darkModeChangeTo !== null) 
                envDispatch({
                    type: 'darkMode',
                    payload: darkModeChangeTo
                })
        })
    })

    return <footer className={!user.id? style.loggedOut : style.loggedIn}>
        <div className={`max-page-width ${style.container} fontSize-small `}>
            <span>&copy;{new Date().getFullYear()} Echelon Front, LLC</span>
            <div className={style.links}>
                <a href="https://www.echelonfront.com"
                className={style.link}
                target="_blank"
                rel="noopener noreferrer"
                >Echelon Front</a>
                <a href="https://academy.echelonfront.com"
                title="Extreme Ownership Academy"
                className={style.link}
                target="_blank"
                rel="noopener noreferrer"
                >Academy</a>
            </div>
            <div className={`no-select ${style.darkModeModule}`}
            title={!user.id? `Login for this feature.` : `Toggle Dark Mode`}
            onClick={() => {
                if (user.id) envDispatch({type: 'toggleDarkMode'})
            }}>
                dark-mode: {!user.id? <>&nbsp;--</> : env.darkMode? <>&nbsp;on</> : `off`}
            </div>
        </div>
    </footer>
}

export default Footer