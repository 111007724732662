import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { nought } from '../helpers/helpers'

export default function Modal ({
    open,
    children,
    onClose = () => {}
}) {
    useEffect(() => document.addEventListener('keypress', e => {
        switch (e.key) {
            case 'Escape':
            case 'Enter': 
                onClose()
                break;
            default: 
                break;
        }
    }))

    return !open? false
    : ReactDOM.createPortal([
        <div id="modalBg" onClick={onClose}/>,
        <div id="modalFg">{!children? nought : children}</div>
    ], document.getElementById('modal'))
}