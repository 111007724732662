import { timeUnix } from "../helpers/helpers";

const userInitialCommon = {
    id: null,
    fullName: '',
    email: '',
    title: '',
    admin: null,
    // sessionCookie: null
}
const userAssumedInitialCommon = {
    ...userInitialCommon,
    timeAssumed: null
}

const userInitial = {
    ...userInitialCommon,
    access: null,
    sessionLoggedIn: null,
    assumeUser: userAssumedInitialCommon
}

const userReducer = (state, action) => {
    switch (action.type) {
        case 'login': {
            return {
                ...state,
                loggingIn: false,
                id: action.payload.id,
                email: action.payload.email,
                fullName: action.payload.name,
                title: action.payload.jobTitle,
                admin: action.payload.admin,
                sessionLoggedIn: timeUnix()
            };
        }

        case 'setAssumeUser': {
            return {
                ...state,
                assumeUser: action.payload || userAssumedInitialCommon
            }
        }

        case 'logout': {
            return userInitial;
        }

        default: {
            return state;
        }
    }
}

export {
    userInitial,
    userReducer
};