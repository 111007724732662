import { frontendVersion, serverURL } from '../App'


export const backendApi = async (endpoint = '', param = {}) =>
    await (await fetch(`${serverURL}/api/${endpoint}`, {
        method: 'POST',
        headers: {
            frontendVersion,
            ...![document.domain, window.location.hostname].includes('localhost')? {} : {Authentication: 'asLocalhost'},
        },
        body: JSON.stringify(param),
    })).json()