import React, { useEffect } from 'react'
import packageJson from '../package.json'
import { RecoilRoot } from 'recoil'
import style from './App.module.css'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import {envReducer, envInitial} from './reducers/EnvReducer'
import {userReducer, userInitial} from './reducers/UserReducer'

import Header from './components/Header'
import Footer from './components/Footer'

import BackgroundProcesses from './components/BackgroundProcesses'

/* Pages */
import Login from './components/Login'
import Home from './components/Home'
import Vouchers from './components/Vouchers'
// import Voucher from './components/Voucher'
import HRPage from './components/HRPage'
import NoPageFound from './components/NoPageFound'
import Admin from './components/Admin'
import {EditEventSchedule} from './components/EventSchedule'
import Nav from './components/Nav'

export const EnvContext = React.createContext()
export const UserContext = React.createContext()

export const serverURL = `https://portal.echelonfront.com` // changed from https://portal.echelonfront.com 2022-12-29
export const frontendVersion = packageJson.version

export const devStage = 
  // 'dev'
  'prod'

export const commonQueryParam = {
  enabled: true,
  refetchInterval: 60 * 5*60*1000 /*Every 5 minutes*/,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  retry: false,
  staleTime: Infinity,
  onError: console.error
}

const App = () => {
  const url = new URL(window.location.href)
  let urlSearch = url.search
  
  const [env, envDispatch] = React.useReducer(envReducer, envInitial)
  const [user, userDispatch] = React.useReducer(userReducer, userInitial)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: commonQueryParam
    }
  })

  /**When the app loads, reload the site after 1 day */
  useEffect(() => setTimeout(() => window.location.reload(), 86400000), [])

  useEffect(() => envDispatch({
    type: 'offline',
    payload: !navigator.onLine
  }), [navigator.onLine])

  //
  // const onLogout = async () => fetch(`${serverURL}/api/logoutAction`, {
  //   method: 'POST'
  // }).then(response => response.json())
  // .then(onLogoutSuccess)

  return <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <EnvContext.Provider value={{env, envDispatch}}>
      <UserContext.Provider value={{user, userDispatch}}>
        <BackgroundProcesses/>
        <div className={[
          'App',
          style.wrapper,
          !!user.id? `${style.loggedIn} ${style.opaque}` : `${style.loggedOut} ${style.transparent}`,
          navigator.onLine? '' : style.offline
        ].join(' ')}
        style={!env.darkMode? {} : {background: 'transparent'}}
        darkmode={!env.darkMode? `off` : 'on'}>
          <div className={style.offlineError}>{!navigator.onLine && 'YOU ARE OFFLINE'}</div>
          <BrowserRouter>
            <Header/>
            <div className={[
              style.stage,
              env.offline? style.offline:'',
              'min-page-width',
              'max-page-width'
            ].join(' ')}>
              {!user.id? <Login/> : <>
                <Nav/>
                <Switch>
                  <Route path="/editEventSchedule" exact component={EditEventSchedule}/>
                  <Route path="/" exact component={Home}/>
                  <Route path="/hr" component={HRPage}/>
                  <Route path="/vouchers/" component={Vouchers}/>
                  {/* <Route path="/voucher/:id" component={Voucher}/>
                  <Accordion onLogout={onLogout}/> */}
                  <Route path="/admin" component={Admin}/>
                  <Route component={() => urlSearch.includes('login-link=1')? <Redirect to="/"/> : <NoPageFound/>}/>
                </Switch>
              </>}
            </div>
          </BrowserRouter>
          <Footer/>
        </div>
        <div className={[
          'backgroundImage',
          style.backgroundImage,
          !user.id? style.show : style.hide
        ].join(' ')}></div>
      </UserContext.Provider>
      </EnvContext.Provider>
    </QueryClientProvider>
  </RecoilRoot>
}

export default App