import {useContext, useEffect, useCallback, useState} from 'react'
import { useSetRecoilState } from 'recoil'
// import { useEventLog } from 'react-log-driver'

import { devStage, EnvContext, UserContext } from '../App'
import { useCheckSessionQuery, useDataQuery } from '../api/Queries'
import {getWidth, noWidows} from '../helpers/helpers'
import { setAccountsSelector, setVouchersSelector } from '../atoms/Atoms'

import Modal from './Modal'


const BackgroundProcesses = () => {
    if (devStage === 'dev') console.count('BackgroundProcesses')

    const {env, envDispatch} = useContext(EnvContext)
    const {user, userDispatch} = useContext(UserContext)

    /**Programmatically have mobileSize available in env state */
    let pageWidth = getWidth()
    useEffect(pageWidth => {
        let mobileSize = pageWidth <= 420
        if ( /* We need to change the env state? */
            env.mobileSize === null
            || (mobileSize && !env.mobileSize)
            || (!mobileSize && env.mobileSize)
        ) envDispatch({
            type: 'mobileSize',
            payload: mobileSize
        })
    }, [pageWidth])
    
    /**Prepare the recoil setters */
    const setAccounts = useSetRecoilState(setAccountsSelector)
    const setVouchers = useSetRecoilState(setVouchersSelector)
    //
    /**Prepare the user-data queries */
    // const loadAccounts = useDataQuery('accounts', setAccounts, true)
    // const loadOpportunities = useDataQuery('opportunities', setVouchers, true)
    const loadVouchers = useDataQuery('vouchers', setVouchers, true)
    //
    /**When the user if logged in, we call for their data */
    useEffect(() => {
        if (navigator.onLine) {
            // loadAccounts()
            // loadOpportunities()
            loadVouchers()
        }
    }, [user.id])
    
    /**Ask API if session */
    /**The browser needs to get the user data from the server.
     * But if using localhost, the cookie will not be saved.
     * So we request user data in the body-payload with the cookie given to us now.
     */
    const [reloaderModal, setReloaderModal] = useState(false)
    const checkSessionOnSuccess = useCallback(response => {
        if (devStage === 'dev') console.log('checkSessionOnSuccess')
        if (response.success && response.data.id !== user.id)
            userDispatch({
                type: 'login',
                payload: response.data
            })
        //
        /**Check for the most recent frontendVersion
         * If unacceptable, we must reload the webapp.
         */
        if (response.frontendVersionIsAccepted === false) setReloaderModal(true)
    }, [])
    const checkSession = useCheckSessionQuery({}, checkSessionOnSuccess)

    return !reloaderModal? [] : <ReloadModal/>
}

const ReloadModal = () => {
    const [countdown, setCountdown] = useState(30)
    const runCountdownTimer = useCallback(() => setCountdown(countdown => (countdown-1)), [])
    useEffect(() => setInterval(runCountdownTimer, 1000), [])

    if (countdown === 0) window.location.reload()
    
    return <Modal open={true}>
        <center>
            <h2>Reloading Page</h2>
            <p>Your version of the site {noWidows('is outdated.')}</p>
            <p>This page will reload in <span className="no-wrap"><strong>{countdown} seconds</strong>...</span></p>
            <button onClick={() => window.location.reload()}>Reload Now</button>
        </center>
    </Modal>
}

export default BackgroundProcesses