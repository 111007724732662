import React, { useCallback, useState, useRef, useEffect } from 'react'
import css from './Header.module.css'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { UserContext } from '../App'
import UserBox from './UserBox'
import AssumeUserBox from './AssumeUserBox'
import { useRecoilValue } from 'recoil'
import { searchSelectorState } from '../atoms/Atoms'
import { truncatedStringWithEllipsis, useKeyPress } from '../helpers/helpers'


const Header = () => {
    const {user} = React.useContext(UserContext)

    const [searchOpen, setSearchOpen] = useState(false)
    const toggleSearchOpen = useCallback(() => {
        // console.count('toggleSearchOpen()')
        if (user.id) setSearchOpen(!searchOpen)
    }, [searchOpen])
    //
    /**Listen for CTRL+F keys */
    // const ctrlIsDown = useKeyPress('Control')
    // const metaIsDown = useKeyPress('Meta')
    /**Detect F key and open search */
    // const determineSearchOpen = e => {
    //     console.info('determineSearchOpen', user.id, ctrlIsDown, metaIsDown, searchOpen, e.key)
    //     let holdingCmdKey = user.id && (ctrlIsDown || metaIsDown)
    //     if (holdingCmdKey
    //     && !searchOpen 
    //     && e.key === 'f') {
    //         e.preventDefault()
    //         setSearchOpen(true)
    //     } else if (searchOpen && e.key === "Escape") setSearchOpen(false)
    // }
    //
    // useEffect(() => {
    //     document.addEventListener('keypress', determineSearchOpen)
    //     return () => document.removeEventListener('keypress', determineSearchOpen)
    // }, [user.id, ctrlIsDown, metaIsDown, searchOpen])
    
    return <header className={!user.id? css.loggedOut : css.loggedIn}>
        <div className={`max-page-width ${css.container}`}>
            <div className={css.brand}>
                <Link to="/" title="Home" className={css.logoLink}>
                    <img className={css.logo} src={logo} alt="Echelon Front" />
                    <span className={`no-wrap ${css.title} fontSize-header`}>Portal</span>
                </Link>
                {/* {user.id && <span className={css.searchButton}
                    onClick={toggleSearchOpen}
                >🔍</span>}
                <Modal open={searchOpen} onClose={toggleSearchOpen}>
                    <SearchModal/>
                </Modal> */}
            </div>
            <div className={css.user}>
                <UserBox/>
            </div>
        </div>
        <AssumeUserBox/>
    </header>
}

const SearchModal = () => {
    const searchRef = useRef(null)
    const [searchTerm, setSearchTerm] = useState('')
    const handleSearchChange = useCallback(e => setSearchTerm(e.target.value))
    useEffect(() => searchRef.current.focus(), [])

    /**Each result can be  */
    const tabIndexPrefix = Math.floor(Math.random()*100)*10
    
    return <div className={css.search}>
        <input id="searchAppBoxInput"
        ref={searchRef}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search Your Info"
        tabIndex={tabIndexPrefix}
        />
        {searchTerm.trim().length === 0?
        <label htmlFor="searchAppBoxInput" 
        className='no-select'
        style={{marginTop: 'var(--margin)', cursor: 'pointer'}}
        >Search this site or your employment data</label>
        : <SearchResults term={searchTerm} tabIndexPrefix={tabIndexPrefix}/>}
    </div>
}

const SearchResults = ({term, tabIndexPrefix}) => {
    const results = useRecoilValue(searchSelectorState(term.trim()))

    let showResults = {
        ...results,
        pages: []
    }
    //
    if (['hr', 'human', 'resources', 'info', 'benefits'].indexOf(term) > -1) showResults.pages.push({
        type: 'page',
        name: 'Human Resources Page',
        id: null,
        link: '/hr'
    })

    return <div className={css.results}>
        {showResults.pages.length > 0
        && <><h3 style={{margin: 0}}>Pages</h3>
        {showResults.pages.map((result, i) => <Link tabIndex={tabIndexPrefix+i} key={`searchResults:${result.type}:${result.link}`}
        to={result.link}>
            {result.name}
        </Link>)}
        </>}
        {showResults.accounts.length > 0
        && <><h3 style={{margin: 0}}>Accounts</h3>
        {showResults.accounts.map((result, i) => 
            <Link tabIndex={tabIndexPrefix+i+showResults.pages.length} key={`searchResult:${result.type}:${result.id}`}
            to={`/${result.type}/${result.id}`}
            >{result.name}</Link>
        )}</>}
    </div>
}

export default Header