import React, { useCallback, useState } from 'react'
import { Link, NavLink, Route, Switch, useHistory} from 'react-router-dom'

import { useMutation, useQuery } from '@tanstack/react-query'

import table from './DataTable.module.css'
import site from '../Site.module.css'

import { serverURL, UserContext } from '../App'
import LoadingAnimation from './LoadingAnimation'

import AllUserSessions from './AllUserSessions'
import AllUserEvents from './AllUserEvents'

import WebsiteFeatures from './WebsiteFeatures'
import { backendApi } from '../api/Backend'
import { isValidEmail } from '../helpers/helpers'
import Modal from './Modal'


const fetchUsers = async (headers = {}) => {
    // const {user, userDispatch} = React.useContext(UserContext)
    const res = await fetch(`${serverURL}/api/admin`, {
        method: 'POST',
        body: JSON.stringify({
            dataRequest: 'all-users'
        }),
        // headers: {}
        headers: {...headers}
        // headers: {'Authentication': user.sessionCookie}
    })
    return res.json()
}

function useFetchUsers(headers = {}) {
    return useQuery({
        queryKey: ['fetchUsers'],
        queryFn: () => fetchUsers(headers)
    })
}
    // const fetchUsers = async (headers = {}) => {
    //     const res = await fetch(`https://portal.echelonfront.com/api/admin`, {
    //     // const res = await fetch(`${serverURL}/api/admin`, {
    //         method: 'POST',
    //         body: JSON.stringify({
    //             dataRequest: 'all-users'
    //         }),
    //         headers: {...headers}
    //         // headers: {'Authentication': user.sessionCookie}
    //     })
    //     return res.json()
    // }

const AllUsers = () => {
    const {user} = React.useContext(UserContext)
    
    // Access the client
    // const cache = useQueryCache();
    const {data, status, refetch} = useQuery({
        queryKey: ['fetchUsers'],
        queryFn: () => fetchUsers({'Authentication': user.sessionCookie})
    })

    const action = useMutation({
        mutationKey: ['Admin', 'AllUsers', 'action'],
        mutationFn: param => backendApi('user-action', param),
        onSuccess: refetch
    })

    // const [debugga, setDebugga] = useState(+ new Date()/1000)
    // useEffect(() => setDebugga(+ new Date()/1000), [status])
    
    const [successDialog, setSuccessDialog] = useState(false)
    const toggleSuccessDialog = useCallback(() => setSuccessDialog(!successDialog), [successDialog])

    return <>
        {/* <h2>{debugga}</h2> */}
        {/* <i>typeof allUsers = {typeof allUsers}</i> */}
        {status === 'loading' && (
            <LoadingAnimation/>
        )}
        {status === 'error' && (
            <div>Error</div>
        )}
        {status === 'success' && (
            <div>
                {/* <pre>
                    {JSON.stringify(data, '', 2)}
                </pre> */}
                <div className={table.DataTable}>
                    {data && data.userMessages.length > 0 && <div>{
                        data.userMessages.map(msg => <div key={msg}>{msg}</div>)
                    }<br/></div>}
                    {/* <pre>{JSON.stringify(data, '', 2)}</pre> */}
                    <table className="fontSize-medium">
                        <colgroup>
                            <col span="1"/>
                            <col span="1"/>
                            <col span="1"/>
                            <col span="1"/>
                            <col span="1"/>
                        </colgroup>
                        <thead>
                            <tr className='fontSize-small'>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Has Portal Access?</th>
                                <th>Admin?</th>
                                <th>Active?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {('data' in data) && (data.data === null || Object.keys(data.data).length < 1)? <tr>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr> : Object.keys(data.data).map(thisUserId => <ThisUserRow key={`Admin:AllUsers:${thisUserId}`} {...{refetch, setSuccessDialog}} thisUser={data.data[thisUserId]}/>)}
                        </tbody>
                        {/* <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><input/></td>
                                <td></td>
                            </tr>
                        </tbody> */}
                    </table>
                    <br/>
                    <br/>
                    <Link to="/admin/users/invite" style={{width: 'fit-content'}}>Add users</Link>
                    <Modal open={successDialog} onClose={toggleSuccessDialog}>
                        <h2>Success</h2>
                        <p className='no-wrap'>User action was performed.</p>
                    </Modal>
                </div>
            </div>
        )}
    </>
}
//
const ThisUserRow = ({refetch, thisUser, setSuccessDialog}) => {
    const checkAccess = useQuery({
        queryKey: ['Admin', 'AllUsers', 'ThisUserRow', thisUser.id],
        queryFn: () => backendApi('user-access', {id: thisUser.id}),
        refetchOnWindowFocus: true
    })
    let hasAccess = checkAccess.isSuccess && checkAccess.data.result
    //
    const action = useMutation({
        mutationKey: ['Admin', 'AllUsers', 'action'],
        mutationFn: param => backendApi('user-action', param),
        onSuccess: refetch
    })
    //
    return <tr>
        <td title={`Salesforce ID = ${thisUser.salesforceId}`}>{thisUser.name}</td>
        <td title={`Salesforce ID = ${thisUser.salesforceId}`} className='fontSize-small'>{thisUser.email}</td>
        <td className='fontSize-small'>
            {checkAccess.isLoading || checkAccess.isFetching? 
                <LoadingAnimation size="small"/>
                : <span title={`Salesforce ID = ${thisUser.salesforceId}`}>{hasAccess? 'Yes' : 'No'}</span>
            }&nbsp;&nbsp;
            {thisUser.active && hasAccess
            && <button disabled={action.isLoading || !thisUser.active} 
            className="small"
            title={!hasAccess? 'Allow them portal access before inviting them.' : 'Send them an email to help them login.'}
            onClick={() => action.mutate({
                ...thisUser,
                action: 'invite'
            }, {
                onSuccess: data => {
                    if (data.success) {
                        setSuccessDialog(true)
                        refetch()
                    } else alert(`Error(s): ${data.errors.length < 1? 'unknown' : data.errors.join(', ')}`)
                }
            })}>Invite</button>}
        </td>
        <td className='fontSize-small'>
            <span className='standardFlex' style={{alignItems: 'center', gap: 'var(--smidge)'}}>
                <input type="checkbox" checked={thisUser.admin} 
                style={{width: '2em', height: '2em'}}
                disabled={thisUser.superAdmin}
                onChange={e => action.mutate({
                    ...thisUser,
                    action: 'update-boolean-field',
                    field: 'admin',
                    value: !thisUser.admin
                }, {
                    onSuccess: data => {
                        if (data.success) {
                            setSuccessDialog(true)
                            refetch()
                        } else alert(`Error(s): ${data.errors.length < 1? 'unknown' : data.errors.join(', ')}`)
                    }
                })}/>
                {thisUser.superAdmin && <i>&nbsp;Super</i>}
            </span>
        </td>
        <td className='fontSize-small'>
            <input type="checkbox" checked={thisUser.active}
            style={{width: '2em', height: '2em'}}
            disabled={thisUser.superAdmin}
            onChange={e => action.mutate({
                ...thisUser,
                action: 'update-boolean-field',
                field: 'active',
                value: !thisUser.active
            }, {
                onSuccess: data => {
                    if (data.success) {
                        setSuccessDialog(true)
                        refetch()
                    } else alert(`Error(s): ${data.errors.length < 1? 'unknown' : data.errors.join(', ')}`)
                }
            })}/>
        </td>
    </tr>
}

const Admin = () => {
    const {userDispatch} = React.useContext(UserContext)

    // const params = useParams()
    // const focus = params.focus || `users`
    // const subfocus = params.subfocus || null

    const history = useHistory()

    /**/
    const assumeUserId = (userId, state) => {
        fetch(`${serverURL}/api/assumeUser`, {
            method: 'POST',
            body: JSON.stringify({
                assumeUserState: null,
                userId: null
            }),
        }).then(response => response.json())
        .then(response => {
            if (!response.success || response.errorCodes.length > 0) {
                /**Error */
            } else {
                userDispatch({
                    type: 'setAssumeUser',
                    payload: response.data
                })
                // useResetRecoilState(userRecords)
            }
        })
    }

    
    return <>
        <div className={site.pageHeaderBreadcrumbs}>
            <Link to="/">Home</Link> &#10147; <span className="no-wrap">Admin</span>
        </div>
        <h1 style={{marginBbottom: 'var(--nudge)'}}>Admin Controls</h1>
        <nav className={`${site.nav} fontSize-small`}>
            <NavLink to="/admin" exact activeClassName={site.selected}>All Site Users</NavLink>
            <NavLink to="/admin/users/invite" activeClassName={site.selected} title="List contacts from salesforce marked with Employee Portal Access">Add Users</NavLink>
            <NavLink to="/admin/userSessions" activeClassName={site.selected}>Login Sessions</NavLink>
            <NavLink to="/admin/userEvents" activeClassName={site.selected}>Users' Events</NavLink>
            <NavLink to="/admin/features" activeClassName={site.selected} title="List of site features">About Website</NavLink>
            {/* <NavLink to="/admin/user/create" activeClassName={site.selected} title="For EF Internal Staff">Manually Create User</NavLink> */}
            {/* <NavLink to="/admin/users/recentActivity" activeClassName={site.selected}>Users: Recent Activity</NavLink> */}
            {/* <div className={css.link}>Docebo Users</div> */}
        </nav>
        <div className="page-focus">
            {/* {renderFocus(focus, subfocus)} */}
            <Switch>
                <Route path="/admin" exact component={AllUsers}/>
                <Route path="/admin/users/invite" exact component={InviteUsers}/>
                <Route path="/admin/userSessions" exact component={AllUserSessions}/>
                <Route path="/admin/userEvents" exact component={AllUserEvents}/>
                <Route path="/admin/features" exact component={WebsiteFeatures}/>
                {/* <Route path="/admin/person/:personId" exact component={NoPageFound}/> */}
                {/* <Route path="/admin/person/:userId" exact component={ThisPerson}/> */}
                <Route>--</Route>
            </Switch>
        </div>
    </>
}

const fieldsetStyle = {
    borderColor: 'var(--inverse-alpha-15)',
    background: 'var(--inverse-alpha-062)'
}
const legendStyle = {
    fontWeight: 700
}

const InviteUsers = () => {
    // DEV_REMINDER:2023-08-25:Make into "All Salesforce Contacts With Portal Access and Are not Users here!" && otherwise goto "All Site Users" to find your peoples' accounts on this site.

    // $person = Person::by_email($email);
    const [emailAddress, setEmailAddress] = useState('')
    const handleEmailAddressChange = useCallback(e => setEmailAddress(e.target.value), [])

    const allContacts = useQuery({
        queryKey: ['AllContactsInSalesforce'],
        queryFn: () => backendApi('salesforce-contact-query'),
        refetchOnMount: true,
        refetchOnWindowFocus: true
    })

    return <div>
        <br/>
        <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>
                <label htmlFor='InviteUsers:email'>Search Salesforce Contacts by Email</label>
            </legend>
            <p className='fontSize-small'>Contacts must have "Employee Portal Access" checked true.</p>
            <div className='standardFlex'>
                <input id='InviteUsers:email' 
                    placeholder='abc@xyz.com'
                    style={{padding: 'var(--padding)'}} 
                    value={emailAddress}
                    onChange={handleEmailAddressChange}
                    />
                {emailAddress.length > 3 && !isValidEmail(emailAddress) && <span className='fontSize-small'>&nbsp;←&nbsp;Input a valid address</span>}
            </div>
            {/* <br/> */}
            <br/>
            <PersonQuerier {...{emailAddress}}/>
        </fieldset>
        <br/>
        <br/>
        <br/>
        <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>All Contacts With Portal Access &nbsp;<button className='small' onClick={allContacts.refetch} disabled={allContacts.isLoading || allContacts.isFetching}>refesh</button></legend>
            {allContacts.isLoading || allContacts.isFetching? <LoadingAnimation/> : allContacts.isSuccess? 
                allContacts.data.data.length > 0? allContacts.data.data.map(p => <Person key={`Person:finder:salesforce:${p.id}`} {...{p}} refetch={allContacts.refetch}/>)
                : <span>--</span>
                : <span>--</span>}
        </fieldset>
    </div>
}
//
const Person = ({p, refetch}) => {
    const action = useMutation({
        mutationKey: ['Person', 'salesforce-database', 'action'],
        mutationFn: param => backendApi('user-action', param),
        onSuccess: refetch
    })
    const [successDialog, setSuccessDialog] = useState(false)
    const toggleSuccessDialog = useCallback(() => setSuccessDialog(!successDialog), [successDialog])

    return <div style={{border: 'var(--letterSpacing) solid', padding: 'var(--padding)', display: 'inline-table'}}>
        <b className='fontSize-prompt'>{p.Name}</b>, <code><i>{p.Title}</i></code>
        <br/>
        <br/>{p.Email}
        <br/>Salesforce ID: &nbsp;{p.Id}
        <br/>Has Portal Access? &nbsp;<b>{p.Emp_Portal_Access__c? 'Yes' : 'No'}</b>
        <br/>
        <br/>
        <div className='standardFlex'>
            {!p.isPortalUser && <button disabled={action.isLoading || p.isPortalUser} 
                title={'Add this user to the portal database.'}
                onClick={() => action.mutate({
                    ...p,
                    action: 'add'
                }, {
                    onSuccess: data => {
                        if (data.success) {
                            setSuccessDialog(true)
                            refetch()
                        }
                    }
                })}>Add</button>}
            <button disabled={action.isLoading || !p.Emp_Portal_Access__c} 
                title={!p.Emp_Portal_Access__c? 'Allow them portal access before inviting them.' : 'Send them an email to help them login.'}
                onClick={() => action.mutate({
                    ...p,
                    action: 'invite'
                }, {
                    onSuccess: data => {
                        if (data.success) {
                            setSuccessDialog(true)
                            refetch()
                        }
                    }
                })}>Invite</button>
            {action.isLoading && <LoadingAnimation/>}
        </div>
        {action.isSuccess && action.data.errors.length > 0 && <p>Error: {action.data.errors[0]}</p>}
        <Modal open={successDialog} onClose={toggleSuccessDialog}>
            <h2>Success</h2>
            <p className='no-wrap'>User action was performed.</p>
        </Modal>
    </div>
}
//
const PersonQuerier = ({emailAddress}) => {
    const query = useQuery({
        queryKey: ['PersonQuerier', emailAddress],
        queryFn: () => backendApi('person-querier', {emailAddress}),
        enabled: emailAddress.length > 0 && isValidEmail(emailAddress)
    })
    
    return emailAddress.length < 1? '--' : 
        (query.isLoading || query.isFetching)? <LoadingAnimation/>
        : query.isSuccess? 
            query.data.data.length < 1? 'None found'
            : <div>{
                // JSON.stringify(query.data.data, '', 2)
                query.data.data.map(p => <Person key={`Person:salesforce:${p.id}`} {...{p}}/>)
            }</div>
        : 'Error'
}

export default Admin