import React, { useCallback, useState } from 'react'
import {Link, NavLink, useRouteMatch} from 'react-router-dom'
import {numberWithCommas, showAmt} from '../helpers/helpers'

import site from '../Site.module.css'
import css from './Vouchers.module.css'

import LoadingAnimation from './LoadingAnimation'
import { backendApi } from '../api/Backend'
import { useQuery } from '@tanstack/react-query'


const voucherAmt = voucher => parseFloat(`${voucher.status === 'Paid'? voucher.paidAmount : voucher.totalAmount}`.replace(',',''))
const voucherYear = voucher => Number(voucher.date.slice(0, 4))

const Vouchers = () => {
    // const {user} = React.useContext(UserContext)
    // const [errors, setErrors] = useState([])
    // const loadOpportunities = useDataQuery('opportunities')
    
    // const loadVouchers = useDataQuery('vouchers', data => setErrors(data.userMessages))
    const loadVouchers = useQuery({
        queryKey: ['fetchVouchers'],
        queryFn: () => backendApi('data-vouchers'),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: 900000
    })
    //
    const [sort, setSort] = useState(['date', 'desc'])
    const sorted = (vouchers = []) => vouchers.sort((v1, v2) => 
        v1[sort[0]] === v2[sort[0]]? 0 
        : sort[1] === 'asc'? 
            v1[sort[0]] < v2[sort[0]]? -1 : 1 
            : v2[sort[0]] < v1[sort[0]]? -1 : 1
    )

    // const voucherIds = useRecoilValue(allVouchersIds)
    
    let vouchersLoadedToDisplay = !(!loadVouchers.isSuccess || !('data' in loadVouchers.data))

    let futureGigsTab = useRouteMatch("/vouchers/future")
    /**Handle sorting for pending pays
     * It reads from a different field.
    */
    if (futureGigsTab && sort[0] === 'paidAmount') setSort(['totalAmount', sort[1]])
    else if (!futureGigsTab && sort[0] === 'totalAmount') setSort(['paidAmount', sort[1]])
    //
    let historicalGigsTab = useRouteMatch("/vouchers/history")
    let historicalGigsYearRequested = !historicalGigsTab? null : Number(window.location.pathname.split('/')[window.location.pathname.split('/').length-1])

    const filterVouchersPaidPreviousYears = useCallback(voucher => voucher.status.toLowerCase() === 'paid' && new Date().getFullYear() > new Date(voucher.date).getFullYear(), [])
    const filterVouchersPaidThisYear = useCallback(voucher => voucher.status.toLowerCase() === 'paid' && new Date().getFullYear() === new Date(voucher.date).getFullYear(), [])
    // const filterVouchersPaid = useCallback(voucher => voucher.status.toLowerCase() === 'paid', [])
    const filterVouchersUnpaid = useCallback(voucher => voucher.status.toLowerCase() !== 'paid', [])
    //
    let allVouchers = !vouchersLoadedToDisplay? [] : loadVouchers.data.data
    //
    let allPreviousVoucherYears = !historicalGigsTab? {} : allVouchers.reduce((years, v) => {
        if (v.date === null) return years
        let year = voucherYear(v)
        let amt = voucherAmt(v)
        if (year >= new Date().getFullYear()) return years
        if (!Object.keys(years).includes(`${year}`)) years[year] = amt
        else years[year] = years[year] + amt
        return years
    }, {})
    //
    let historicalGigsYearRequestGranted = Object.keys(allPreviousVoucherYears).includes(`${historicalGigsYearRequested}`)
    //
    let displayVouchers = allVouchers.filter(futureGigsTab? filterVouchersUnpaid : !historicalGigsTab? filterVouchersPaidThisYear : filterVouchersPaidPreviousYears)
    //
    let displayVouchersSorted = sorted(
        historicalGigsYearRequestGranted? 
        displayVouchers.filter(v => voucherYear(v) === historicalGigsYearRequested)
        : displayVouchers
    )
    //
    let displayVouchersByDay = (
        historicalGigsYearRequestGranted? 
        displayVouchersSorted.filter(v => voucherYear(v) === historicalGigsYearRequested)
        : displayVouchersSorted 
    ).reduce((days, voucher) => {
        if (!(voucher.date in days)) days[voucher.date] = [voucher]
        else days[voucher.date].push(voucher)
        return days
    }, {})
    
    
    /**Loop through all opportunities & payments for this opporunity */
    const calcTotal = useCallback((total, voucher) => total + voucherAmt(voucher), [])
    let totalPaymentsPaidPreviousYears = allVouchers.filter(filterVouchersPaidPreviousYears).reduce(calcTotal, 0)
    let totalPaymentsPaidThisYear = allVouchers.filter(filterVouchersPaidThisYear).reduce(calcTotal, 0)
    // let totalPaymentsPaid = allVouchers.filter(filterVouchersPaid).reduce(calcTotal, 0)
    let totalPaymentsUnpaid = allVouchers.filter(filterVouchersUnpaid).reduce(calcTotal, 0)

    const displayPayDateRows = date => {
        let vouchers = displayVouchersByDay[date]
        let dateTotal = vouchers.reduce(calcTotal, 0)
        return [
            ...vouchers.map((voucher, i) => <tr key={`vouchers:${voucher.id}:${i}`}>
                {!i && <td className='no-wrap' style={{verticalAlign: 'top'}} rowSpan={displayVouchersByDay[date].length + 1}>{date}</td>}
                <td>{voucher.name}</td>
                <td>{showAmt(voucherAmt(voucher), false)}</td>
            </tr>),
            <tr key={`vouchers:total:${date}`}>
                <td style={{verticalAlign: 'top'}} className='fontSize-small'>Count: {vouchers.length}</td>
                <td title='Subtotal'><b><i>{showAmt(dateTotal, false)}</i></b><br/><br/></td>
            </tr>
        ]
    }

    return <div className={`page ${css.Vouchers}`}>
        <div className={css.header}>
            <div className={site.pageHeaderBreadcrumbs}>
                <Link to="/">Home</Link> &#10147; <span>Vouchers</span>
            </div>
            <br/>
            <h1>Payment Vouchers</h1>
            {/* <div className={site.pageHeader}>
            </div> */}
            {(loadVouchers.isLoading || loadVouchers.isFetching)? <LoadingAnimation/>
            : <div className='standardFlex'>
                {loadVouchers.isSuccess && loadVouchers.data.userMessages.length > 0? <>
                    <ul style={loadVouchers.isFetching? {opacity: .38} : {}}>{loadVouchers.data.userMessages.map(err => <li>{err}</li>)}</ul>
                    <button disabled={loadVouchers.isFetching} onClick={loadVouchers.refetch}>Retry</button>
                </>
                : <nav className={`standardFlex ${css.tabs} no-select`}>
                    <NavLink to="/vouchers/" activeClassName={css.active} exact>
                        <span className='fontSize-header'>Paid in {new Date().getFullYear()}</span>
                        <span className="fontSize-small">{!vouchersLoadedToDisplay? <LoadingAnimation size="small"/> : `$${numberWithCommas(totalPaymentsPaidThisYear.toFixed(2))}`}</span>
                    </NavLink>
                    <NavLink to="/vouchers/future" activeClassName={css.active} exact>
                        <span className='fontSize-header'>Pending Pays</span>
                        <span className="fontSize-small">{!vouchersLoadedToDisplay? <LoadingAnimation size="small"/> : `${allVouchers.filter(filterVouchersUnpaid).length} Vouchers`}</span>
                        {/* <span>...</span> */}
                    </NavLink>
                    <NavLink to="/vouchers/history" activeClassName={css.active}>
                        <span className='fontSize-header'>Historical</span>
                        <span className="fontSize-small">{!vouchersLoadedToDisplay? <LoadingAnimation size="small"/> : `$${numberWithCommas(totalPaymentsPaidPreviousYears.toFixed(2))}`}</span>
                    </NavLink>
                </nav>}
            </div>}
        </div>
        <div className={css.body}>
            {loadVouchers.isSuccess && loadVouchers.data.userMessages.length === 0 && <>
                {/* <DataTable className={css.table} type="vouchers" hideTitle={true}/> */}
                {historicalGigsTab 
                && Object.entries(allPreviousVoucherYears).length > 0 
                && <nav style={{margin: 'var(--margin) 0 0'}} className={`standardFlex ${css.tabs} no-select`}>{Object.keys(allPreviousVoucherYears).map(year => 
                    <NavLink to={`/vouchers/history/${year}`}
                    activeClassName={css.active}
                    key={`allPreviousVoucherYears:${year}`}
                    title={`Total ${showAmt(allPreviousVoucherYears[year])}`}>
                        <span className='fontSize-header'>{year}</span>
                        <span className='fontSize-small'>{showAmt(allPreviousVoucherYears[year])}</span>
                    </NavLink>
                )}</nav>}
                <table className='standard'>
                    <thead>
                        <tr>
                            <th scope='col' className='commonFlex no-select' style={{cursor: 'pointer'}} onClick={() => setSort(['date', sort[0] !== 'date'? sort[1] : sort[1] === 'asc'? 'desc' : 'asc'])}>Date &nbsp; <span style={sort[0] !== 'date'? {pointerEvents: 'none', visibility: 'hidden'} : {color: 'var(--ef-red)'}}>{sort[1] === 'desc'?'↓':'↑'}</span></th>
                            <th scope='col' className='commonFlex no-select' style={{cursor: 'pointer'}} onClick={() => setSort(['name', sort[0] !== 'name'? sort[1] : sort[1] === 'asc'? 'desc' : 'asc'])}>Name &nbsp; <span style={sort[0] !== 'name'? {pointerEvents: 'none', visibility: 'hidden'} : {color: 'var(--ef-red)'}}>{sort[1] === 'desc'?'↓':'↑'}</span></th>
                            <th scope='col' className='commonFlex no-select' style={{cursor: 'pointer'}} onClick={() => setSort([!futureGigsTab? 'paidAmount' : 'totalAmount', !['paidAmount', 'totalAmount'].includes(sort[0])? sort[1] : sort[1] === 'asc'? 'desc' : 'asc'])}>Paid Amount &nbsp; <span style={!['paidAmount', 'totalAmount'].includes(sort[0])? {pointerEvents: 'none', visibility: 'hidden'} : {color: 'var(--ef-red)'}}>{sort[1] === 'desc'?'↓':'↑'}</span></th>
                            {/* <th scope='col'>Status</th> */}
                            {/* <th scope='col'>Notes</th> */}
                        </tr>
                    </thead>
                    <tbody>{
                        sort[0] !== 'date' || futureGigsTab? displayVouchersSorted.map(VoucherDisplay)
                        : Object.keys(displayVouchersByDay).map(displayPayDateRows)
                    }</tbody>
                    <tfoot>
                        <tr className='fontSize-small'>
                            <td>{!futureGigsTab && 'All Payments'}</td>
                            <td>Count: {sort[0] !== 'date' || futureGigsTab? displayVouchersSorted.length : Object.keys(displayVouchersByDay).reduce((total, d) => (total + displayVouchersByDay[d].length), 0)}</td>
                            <td style={{textDecoration: 'underline', textDecorationStyle: 'double', textDecorationColor: 'rgba(150,150,150,.85)'}}><b>{showAmt(futureGigsTab? totalPaymentsUnpaid : !historicalGigsTab? totalPaymentsPaidThisYear : historicalGigsYearRequestGranted? allPreviousVoucherYears[historicalGigsYearRequested] : totalPaymentsPaidPreviousYears)}</b></td>
                        </tr>
                    </tfoot>
                </table>
            </>}
        </div>
    </div>
}
//
const VoucherDisplay = voucher => 
    <tr key={`Vouchers:VoucherDisplay:${voucher.id}`}>
        <td className='no-wrap'>{voucher.date}</td>
        <td>{voucher.name}</td>
        <td>{showAmt(voucherAmt(voucher), false)}</td>
        <td>{voucher.status}</td>
        {/* <td>{voucher.notes}</td> */}
    </tr>

export default Vouchers