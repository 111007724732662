import { get, result } from 'lodash'
import {atom, atomFamily, selector, selectorFamily} from 'recoil'
import { timeUnix } from '../helpers/helpers'

/**This selector should be able to return the requested object with related objects included. */
export const selectInfo = selectorFamily({
    key: 'selectObject',
    get: (getWhat, id) => ({get}) => {
        switch (getWhat) {
            case 'account': return selectAccount(id)
            // case 'opportunity': return selectOpportunity(id)
            case 'voucher': return selectVoucher(id)
            default: return null
        }
    }
})


export const searchSelectorState = selectorFamily({
    key: 'SearchSelector',
    get: (searchTerm) => ({get}) => {
        let results = {
            accounts: [],
            vouchers: []
        }
        let allAccounts = get(allAccountsIds).map(id => get(accountsState(id)))
        if (searchTerm.length > 0) {
            switch (searchTerm.length) {
                case 1:
                    /**Check accounts' first letter*/
                    results.accounts = allAccounts.reduce((all, a) => ([
                        ...all,
                        ...a.Name.charAt(0).toLowerCase() !== searchTerm.toLowerCase()? []
                            : [{
                                type: 'account',
                                name: a.Name,
                                id: a.id
                            }]
                    ]), [])
                    break;

                default: 
                    /**Check Account Names for string indexes */
                    results.accounts = allAccounts.reduce((all, a) => ([
                        ...all,
                        ...a.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0? [] 
                            : [{
                                type: 'account',
                                name: a.Name,
                                id: a.id
                            }]
                    ]), [])
                    //
                    /**Check Opportunity Descriptions for string indexes */
                    // let allOpportunities = get(allOpportunitiesIds).map(id => get(opportunitiesState(id)))
                    // results.opportunities = allOpportunities.reduce((all, o) => {
                    //     let searchByField = o.Brief_Event_Description__c || o.Event_Type__c || `${o.City__c}, ${o.State__c}`
                    //     if (searchByField.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) 
                    //         all.push({
                    //             type: 'opportunity',
                    //             name: searchByField,
                    //             id: o.id
                    //         })
                    //     return all
                    // }, [])
                    break;
            }
        }
        return results
    }
})


const selectAccount = selectorFamily({
    key: 'selectAccount',
    get: id => ({get}) => (get(accountsState(id)))
})
//
export const accountsState = atomFamily({
    key: 'accountState',
    default: {
        loaded: false,
        id: null
    }
})
//
export const allAccountsIds = atom({
    key: 'AccountsIds',
    default: []
})
//
export const setAccountsSelector = selector({
    key: 'SetAccountsSelector',
    get: () => {},
    set: ({set}, data) => {
        /**Set each into a separate atom */
        for (let i = 0; i < data.data.length; i++) 
            set(accountsState(data.data[i].Id), {
                ...data.data[i],
                loaded: timeUnix(),
                id: data.data[i].Id,
            })
        /**Set the id into the array of Ids */
        set(allAccountsIds, data.data.reduce((all, a) => ([...all, a.Id]), []))
    }
})


// const selectOpportunity = selectorFamily({
//     key: 'selectOpportunity',
//     get: id => ({get}) => (get(opportunitiesState(id)))
// })
//
// export const opportunitiesState = atomFamily({
//     key: 'OpportunitiesState',
//     default: {
//         loaded: false,
//         id: null
//     }
// })
//
// export const allOpportunitiesIds = atom({
//     key: 'OpportunitiesIds',
//     default: []
// })
//
// export const setOpportunitiesSelector = selector({
//     key: 'SetOpportunitiesSelector',
//     set: ({set}, data) => {
//         /**Set each into a separate atom */
//         for (let i = 0; i < data.data.length; i++) 
//             set(opportunitiesState(data.data[i].Id), {
//                 ...data.data[i],
//                 loaded: + new Date()/1000,
//                 id: data.data[i].Id,
//             })
//         /**Set the id into the array of Ids */
//         set(allOpportunitiesIds, data.data.reduce((all, o) => ([...all, o.Id]), []))
//     }
// })
//
// export const opportunitiesByAccount = selectorFamily({
//     key: 'OpportunityByAccount',
//     get: id => ({get}) => (get(allOpportunitiesIds).reduce((all, oppId) => {
//         let opportunity = get(opportunitiesState(oppId))
//         return [...all, ...(!opportunity.loaded || opportunity.AccountId !== id)? [] : [opportunity]]
//     }, []))
// })


const selectVoucher = selectorFamily({
    key: 'selectVoucher',
    get: id => ({get}) => (get(vouchersState(id)))
})
//
export const vouchersState = atomFamily({
    key: 'VouchersState',
    default: {
        loaded: false,
        id: null
    }
})
//
export const allVouchersIds = atom({
    key: 'VouchersIds',
    default: []
})
//
export const setVouchersSelector = selector({
    key: 'SetVouchersSelector',
    get: () => {},
    set: ({set}, data) => {
        /**Set each into a separate atom */
        for (let i = 0; i < data.data.length; i++) 
            set(vouchersState(data.data[i].id), {
                ...data.data[i],
                loaded: timeUnix(),
                id: data.data[i].id,
            })
        /**Set the id into the array of Ids */
        set(allVouchersIds, data.data.reduce((all, v) => ([...all, v.id]), []))
    }
})
//
// export const vouchersByOpportunity = selectorFamily({
//     key: 'VouchersByOpportunity',
//     get: id => ({get}) => (get(allVouchersIds).reduce((all, voucherId) => {
//         let voucher = get(vouchersState(voucherId))
//         return [...all, ...(!voucher.loaded || voucher.opportunity !== id)? [] : [voucher]]
//     }, []))
// })
//
// export const totalPaymentsOfVouchersByOpportunity = selectorFamily({
//     key: 'TotalPaymentsOfVouchersByOpportunity',
//     get: id => ({get}) => {
//         let result = {
//             amtPending: 0,
//             amtComplete: 0,
//             ids: []
//         }
//         let vouchersIds = get(allVouchersIds)
//         for (let i = 0; i < vouchersIds.length; i++) {
//             let voucher = get(vouchersState(vouchersIds[i]))
//             if (voucher.loaded && voucher.opportunity === id) {
//                 /**Add to ids */
//                 result.ids.push(voucher.id)
//                 /**Add to either pending or complete */
//                 if (voucher.status === 'Paid') result.amtComplete = result.amtComplete + voucher.paidAmount
//                 else result.amtPending = result.amtPending + voucher.totalAmount
//             }
//         }
//         return result
//     }
// })
//
// export const totalPaymentsAmountOfVouchersOfOpportunitiesByAccount = selectorFamily({
//     key: 'TotalPaymentsAmountOfVouchersOfOpportunitiesByAccount',
//     get: id => ({get}) => (get(opportunitiesByAccount(id)).reduce((total, o) => (total + get(totalPaymentsOfVouchersByOpportunity(o.id)).amtComplete), 0))
// })