import React from 'react'
import {UserContext} from '../App'
import { useQuery, useMutation } from '@tanstack/react-query'
import table from './DataTable.module.css'
import LoadingAnimation from './LoadingAnimation'
import { displayDate } from '../helpers/helpers'
import { backendApi } from '../api/Backend'


const fetchUserSessions = async (headers = {}) => {
    const res = await fetch(`https://portal.echelonfront.com/api/admin`, {
        method: 'POST',
        body: JSON.stringify({
            dataRequest: 'all-user-sessions'
        }),
        headers: {...headers}
    })
    return res.json()
}


const AllUserSessions = () => {
    const {user} = React.useContext(UserContext)

    const sessions = useQuery({
        queryKey: ['fetchUserSessions'],
        queryFn: () => fetchUserSessions({'Authentication': user.sessionCookie})
    })

    return <>
        {sessions.status === 'loading' && <LoadingAnimation/>}
        {sessions.status === 'error' && <div>Error</div>}
        {sessions.status === 'success' && (
            <div>
                {/* <pre>
                    {JSON.stringify(data, '', 2)}
                </pre> */}
                <div className={table.DataTable}>
                    {sessions.data && sessions.data.userMessages.length > 0 && <div>{
                        sessions.data.userMessages.map(msg => <div key={msg}>{msg}</div>)
                    }<br/></div>}
                    <table className="fontSize-medium">
                        <colgroup>
                            <col span="1"/>
                            <col span="1"/>
                            <col span="1"/>
                            <col span="1"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Logged in</th>
                                <th>Logged out?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!sessions.data.data || sessions.data.data.length < 1)? <tr>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr> : sessions.data.data.map(thisUserSession => <UserSessionRow refetch={sessions.refetch} {...{thisUserSession}}/>)}
                        </tbody>
                    </table>
                </div>
            </div>
        )}
    </>
}

const UserSessionRow = ({thisUserSession, refetch}) => {
    const endSessionMutation = useMutation({
        mutationKey: ['EndUserSession', thisUserSession.id],
        mutationFn: param => backendApi('end-user-session', param)
    })
    const endSession = () => endSessionMutation.mutate({id: thisUserSession.id}, {onSuccess: refetch})
    
    return <tr key={`UserSessionRow:${thisUserSession.id}`}>
        <td>{thisUserSession.name}</td>
        <td>{displayDate(thisUserSession.time, true)}</td>
        <td>{
            !!thisUserSession.timeEnded? displayDate(thisUserSession.timeEnded, true)
            : <button className='small' onClick={endSession}>End</button>
        }</td>
    </tr>
}

export default AllUserSessions