// import {memo} from 'react'
import { useState, useEffect } from 'react'

export const noWrap = string => <span className="no-wrap">{string}</span>

export const noWidows = string => {
    if (!string) return ''
    let words = string.split(' ')
    if (words.length < 3) return string;
    let lastTwoWords = words.slice(words.length-2, words.length).join(' ')
    return (<>
        {`${words.splice(0, words.length - 2).join(' ')} `}
        <span className="no-wrap">{lastTwoWords}</span>
    </>)
}


/**For writing */
export const addAnN = nextWord => {
    if (typeof nextWord !== 'string' || nextWord.length < 1) return ''
    nextWord = nextWord.trim()
    if (nextWord.length < 1) return ''
    const vowels  = ['A', 'a', 'E', 'e', 'F', 'I', 'i', 'O', 'o', 'U', 'u']
    /**If a capitalized acronym, compare against capital letters & vowels */
    if (nextWord === nextWord.toUpperCase()) {
        const acronymFirstLetters = ['A', 'E', 'H', 'I', 'L', 'M', 'N', 'O', 'R', 'S', 'U', 'X']
        return ![...new Set([
            ...acronymFirstLetters,
            ...vowels
        ])].includes(nextWord.charAt(0))? '' : 'n'
    }
    /**If not an acronym, compare against vowels. */
    return !vowels.includes(nextWord.charAt(0))? '' : 'n'
}


export const truncatedStringWithEllipsis = (str, allowedLength = 20) => {
    if (str.trim().length+3 > allowedLength) return `${str.trim().substring(0, allowedLength-3)}...`
    return str
}

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const timeSince = date => {
    if (!date) return {
        seconds: null,
        intervalType: null,
        interval: null,
        displayText: 'Unknown date'
    }
    if (typeof date === 'string') date = new Date(date)

    let seconds = Math.floor((new Date() - date) / 1000)
    let displayText = ''
    
    let intervalType = 'seconds'
    let interval = Math.abs(seconds)
    if (interval <= 60*15 /*within 15 minutes*/) displayText = 'Now!'
    else {
        intervalType = 'years'
        interval = Math.abs(seconds / (86400*365.25/* 1 year */))
        if (interval > 1) displayText = `${interval.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})} yr${Math.floor(interval) > 1? 's' : ''}`
        else {
            intervalType = 'months'
            interval = Math.abs(seconds / (86400*365.25/12))
            if (interval > 1) displayText = displayText = `${Math.floor(interval)} months`
            else {
                intervalType = 'days'
                interval = Math.abs(seconds / 86400)
                if (interval > 1) displayText = `${Math.floor(interval)} days`
                else {
                    intervalType = 'hours'
                    interval = Math.abs(seconds / 3600);
                    if (interval > 1) displayText = `${Math.floor(interval)} hrs`
                    else {
                        intervalType = 'minutes'
                        interval = Math.abs(seconds / 60)
                        if (interval > 1) displayText = `${Math.floor(interval)} min ago`
                        else displayText = `${Math.floor(seconds)} seconds`
                    }
                }
            }
        }
        /* In the past or future? */
        displayText = seconds > 0? `${displayText} ago` : `In ${displayText} from now`
    }

    return {
        seconds,
        intervalType,
        interval,
        displayText
    }
}


export const getWidth = () => {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

export const getHeight = () => {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
    );
}

/**Validate an email address found https://ui.dev/validate-email-address-javascript/ */
export const validateEmailAddress = string => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string)

/**Validate a phone number found http://zparacha.com/phone_number_javascript_regex */
// export const validatePhoneNumber = string => /^\(?(\d{3})\)?[- .]?(\d{3})[- .]?(\d{4})$/.test(string)


/**Keep track of keypress
 * https://usehooks.com/useKeyPress/
 * 2022-04-06
 */
export const useKeyPress = (targetKey) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false)
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
        if (key === targetKey) setKeyPressed(true)
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
        if (key === targetKey) setKeyPressed(false)
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler)
        window.addEventListener("keyup", upHandler)
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler)
            window.removeEventListener("keyup", upHandler)
        }
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed
}


export const displayDate = (string = '', time = false) => {
    string = typeof string === 'string'? string : string * 1000
    
    let date = new Date(string)

    /**Fix timezone offset */
    date = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000))

    let result = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`
    return `${result}${!time? '' : ' '+formatAMPM(date)}`
}

export const formatAMPM = date => {
    date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000))
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let ampm = hours >= 12? 'pm' : 'am'
    hours = hours % 12 || 12
    minutes = `00${minutes}`.slice(-2)
    return `${hours}:${minutes}${ampm}`
}

export const showAmt = (value, dollarSign = true) => `${!dollarSign? '' : '$'}${value.toLocaleString('en', {minimumFractionDigits: 2})}`

export const isValidEmail = email => /\S+@\S+\.\S+/.test(email)


/**Get the current time in UNIX format */
export const timeUnix = () => new Date()/1000


/**Denotes empty value */
export const nought = '--'