import React, { useMemo } from 'react'
import {useHistory} from 'react-router-dom'
import style from './AssumeUserBox.module.css'
import { UserContext } from '../App'


const AssumeUserBox = () => {
    const {user, userDispatch} = React.useContext(UserContext)

    const history = useHistory()

    const person = useMemo(
        () => !user.assumeUser.id? null : user.assumeUser, 
        [user.assumeUser.id]
    )

    const endAssumeUser = () => {
        // tell server to modify session
        userDispatch({type: 'setAssumeUser', payload: false})
        history.push(`/admin/user/${person.id}`)
    }
    
    return <div className={`${style.AssumeUserBox} ${!person? style.hide : style.show}`}>
        <div className={`${style.container} min-page-width max-page-width`}>
            {!!user.assumeUser.id && <>
                You are now seeing what {person.fullName} sees!
                &nbsp;
                &nbsp;
                <button onClick={() => endAssumeUser()}>
                    Leave Their View
                </button>
            </>}
        </div>
    </div>
}

export default AssumeUserBox