import React from 'react'
import { EnvContext } from '../App'
import { Link } from 'react-router-dom'

const WebsiteFeatures = () => {
    const {envDispatch} = React.useContext(EnvContext)

    return <div style={{paddingBottom: 'var(--space)'}}>
        <h3>ABOUT&nbsp;THIS&nbsp;WEBSITE</h3>
        <p>It displays instructor payments, HR information, and the event schedule.</p>
        <br/>
        <br/>
        <h3>HOW&nbsp;TO</h3>
        <ul>
            <li>
                <b>Add users</b>
                <p>To add someone to this site, they need to have "Employee Portal Access" checked on their Salesforce Contact. You may then add and/or invite them.</p>
            </li>
            <li>
                <b>Invite a user</b>
                <p>When you click "Invite" on a user, they are sent an email. They are given a link to login, which expires after 48 hours.</p>
            </li>
            <li>
                <b>Login without an invite link</b>
                <p>Any user may input their email address and they will be sent a 4 digit code to verify themselves with. The site will then grant them access to their profile.</p>
            </li>
            <li>
                <b>Remove users</b>
                <p>You can deactivate users by either unchecking their "Active" status in <Link to="/admin">All Users</Link> or by unchecking their "Employee Portal Access" on their Salesforce contact.</p>
            </li>
            <li>
                <b>Update the Event Schedule</b>
                <p>From the homepage, the Event Schedule will show a link to <Link to="/editEventSchedule">Edit Events</Link>. Modify the table as you see fit; It auto-saves your changes. Remember that when adding events: by default&mdash; it will be labeled as a "draft". You will need to uncheck "draft" for it to show on the schedule.</p>
            </li>
            <li>
                <b>Modifying this website</b>
                <p>You may contact the developer below (and/or <a href="mailto:katy@echelonfront.com?subject=Employee%20Portal">Katy Sewell</a>) to fix anything on this site!</p>
            </li>
        </ul>
        <br/>
        <br/>
        <h3>CURRENT&nbsp;FEATURES</h3>
        <ol>
            {/* <caption>
                <h3>Listed&nbsp;features</h3>
            </caption> */}
            {/* <li>Offline pausing</li>
            <li className='strike'>All Accounts</li>
        <li className='strike'>All Opportunities</li> */}
            <li>Event Schedule</li>
            <li>Your Vouchers</li>
            <li>Admin Page</li>
            <li>Dark Mode (<a href="#" onClick={() => envDispatch({type: 'toggleDarkMode'})}>toggle</a>)</li>
            <li>Installable WebApp<br/>
                <span className='fontSize-small'>
                    Mobile: install on your homescreen
                    <br/>Desktop: install on your dock or taskbar, in Chrome or Edge
                </span>
            </li>
        </ol>
        <br/>
        <br/>
        {false && <><h3>Coming&nbsp;soon (technical)</h3>
        <ol>
            <li>Realtime access using Salesforce Contacts as authority</li>
            <li>"Super Admin" users can manage other users' "Admin" status</li>
            <li>See all emails sent from the portal, to users</li>
            {/* <li>More robust Salesforce connection</li> */}
            <li>Heavier security...<ul style={{listStyle: 'circle'}}>
                <li>Logging all login attempts</li>
                <li>Recording all IP addresses to the site</li>
                <li>Locking down all webapp-endpoints</li>
            </ul></li>
            <li>Enhancements w/ my open source tools:
                <ol style={{listStyle: 'circle'}}>
                    <li>User event capturing in frontend, displaying to "Admins"</li>
                    <li>Persist user preferences, like Dark Mode.</li>
                    <li>Table sorting</li>
                </ol>
            </li>
        </ol>
        <br/>
        <br/></>}
        <h3>DEVELOPMENT</h3>
        <ul style={{listStyle: 'circle'}}>
            <caption>
                <strong>Dan&nbsp;Michael</strong>
                <br/>
            </caption>
            <li>
                <a href="tel:6192485062">619.248.5062</a>
            </li>
            <li>
                <a href="mailto:deskofdaniel@gmail.com?subject=Echelon%20Front%20Employee%20Portal">deskofdaniel@gmail.com</a>
            </li>
            <li>Frontend: ReactJS</li>
            <li>Backend: PHP, MySQL</li>
            <li>DNS/Host: GoDaddy, Bluehost</li>
            <li>Integrations: Salesforce, SendGrid</li>
        </ul>
    </div>
}

export default WebsiteFeatures