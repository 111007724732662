import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import {NavLink, useLocation} from 'react-router-dom'
import { backendApi } from '../api/Backend'
import {UserContext} from '../App'
import style from './Nav.module.css'
import { timeUnix } from '../helpers/helpers'

const activeStyle = {
    opacity: .62,
    pointerEvents: 'none'
}

const Nav = () => {
    const {user} = React.useContext(UserContext)
    const location = useLocation()

    const {refetch: logEvent} = useQuery({
        queryKey: ['pageloadEventLog'],
        queryFn: () => backendApi('userEvents', {
            events: [
                {
                    code: 'pageload',
                    note: 'App loaded or page change',
                    uri: location.pathname,
                    time_unix: Math.round(timeUnix())
                }
            ]
        }),
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    })
    useEffect(logEvent, [location])
    
    return <div className={style.nav}>
        <NavLink to="/" exact {...{activeStyle}}>
            Events
        </NavLink>
        <NavLink to="/vouchers/" {...{activeStyle}}>
            My Vouchers
            {/* {!userData.vouchers.loaded? '': ` (${userData.vouchers.ids.length})`}
            {!env.loading.allOfAssumedUser 
                || !env.loading.allOfAssumedUser.includes('vouchers')?
            '' : <LoadingAnimation/>} */}
        </NavLink>
        <NavLink to="/hr" exact {...{activeStyle}}>
            HR Info
        </NavLink>
        {/* <Link to="/opportunities">
            My Opportunities
            {!userData.opportunities.loaded? '': ` (${userData.opportunities.ids.length})`}
            {!env.loading.allOfAssumedUser 
            || !env.loading.allOfAssumedUser.includes('opportunities')?
                '' : <LoadingAnimation/>}
        </Link>
        <Link to="/accounts">
            My Accounts
            {!userData.accounts.loaded? '': ` (${userData.accounts.ids.length})`}
            {!env.loading.allOfAssumedUser 
            || !env.loading.allOfAssumedUser.includes('accounts')?
                '' : <LoadingAnimation/>}
        </Link> */}
        {/* <Link to="/me">My Profile</Link> */}
        {/* <div><strike>My EF Online Courses</strike></div> */}
        {/* <div><Link to="/dev"><i>Dev Page</i></Link></div> */}
        {user.admin && !(user.assumeUser.id && !user.assumeUser.admin) &&
        <NavLink to="/admin" {...{activeStyle}}>
            Admin Page
        </NavLink>}
    </div>
}

export default Nav