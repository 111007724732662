import React, { memo } from "react"
import { capitalize } from "lodash"
import { useQuery } from '@tanstack/react-query'
import { Link } from "react-router-dom"
import { backendApi } from "../api/Backend"
import { displayDate } from "../helpers/helpers"
import LoadingAnimation from "./LoadingAnimation"
import table from './DataTable.module.css'


const AllUserEvents = () => {
    const events = useQuery({
        queryKey: ['AllUserEvents'],
        queryFn: () => backendApi('userEvents', {
            get: {}
        })
    })
    
    return <>
        {events.isLoading && <LoadingAnimation/>}
        {events.isSuccess && <div className={table.DataTable}>
            <table>
                <thead>
                    <tr style={{textAlign: 'left'}}>
                        <th>User</th>
                        {/* <th>What</th> */}
                        <th>Page</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>{events.data.data.events.results.map(event => 
                    <UserEventRow key={`AllUserEvents:${event.id}`} {...{event}}/>
                )}</tbody>
            </table>
        </div>}
    </>
}
//
const UserEventRow = memo(({event}) => <tr>
    <td title={event.user.jobTitle}>{event.user.name}</td>
    {/* <td title={event.note}>{capitalize(event.code)}</td> */}
    <td><Link to={event.uri} title="Go there">{event.uri}</Link></td>
    <td title={event.time_iso}>{displayDate(event.time_iso, true)} PST</td>
</tr>, (event1, event2) => event1.event.id === event2.event.id)


export default AllUserEvents