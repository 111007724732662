import React, { useCallback } from 'react'
import style from './UserBox.module.css'
import { devStage, UserContext } from '../App'
import { useMutation } from '@tanstack/react-query'
import { backendApi } from '../api/Backend'

const UserBox = ({onLogout}) => {
    const {user} = React.useContext(UserContext)

    /* Logout */
    const onLogoutSuccess = data => {
        if (devStage === 'dev') console.log('onLogoutSuccess', data)
        // if (!data.success || data.errorCodes.length > 0) {
        // userDispatch({type: 'logout'})
        // } else setTimeout(() => window.location.reload(), 250)
        if (data.success) window.location.reload()
    }
    const logoutAction = useMutation({
        mutationKey: ['LogoutAction'], 
        mutationFn: () => backendApi('logoutAction'),
        onSuccess: onLogoutSuccess
    })
    const logoutActionClick = useCallback(() => logoutAction.mutate(), [])

    return <div className={`${style.UserBox} ${!user.id? style.hide : style.show}`} title={Intl.DateTimeFormat().resolvedOptions().timeZone}>
        {user.id && <>
            {/* <NavLink to="/me" 
            activeClassName={style.selected} */}
            <span
            // className={` ${!user.assumeUser.id? '' : style.assumeUser}`} 
            >
                <div className={`no-wrap ${style.name} fontSize-medium`}>{user.fullName}</div>
                {/* <div className={`no-wrap ${style.minimalText} fontSize-small`}>{user.fullName}</div> */}
                <div to="#logout" 
                    className={`${style.logoutLink} fontSize-small no-wrap no-select`}
                    onClick={logoutActionClick}>
                        <u>Sign Out</u>
                </div>
            </span>
            {/* </NavLink> */}
        </>}
    </div>
}

export default UserBox